import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";

function Password() {
  const [preloader, setPreloader] = useState(false);
  const [validationErrors, setValidationErros] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  // Update Password Form Inputs
  const [oldPassword, setOldPassword] = useState(" ");
  const [password, setPassword] = useState(" ");
  const [confirmPassword, setConfirmPassword] = useState(" ");

  // useNavigate hook for redirect after login
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  /*
|========================================================
| Update site settings -- API CALLING
|========================================================
*/
  async function updatePassword(e) {
    e.preventDefault();

    console.log("form Submitted");
    setPreloader(true);

    setErrors([]);
    setValidationErros(false);
    setMessage("");

    // get token from local storage
    const token = localStorage.getItem("token");

    // MAKE FORMDATA FOR CALLING STORE NEW SERVER API
    const formData = new FormData();
    formData.append("old_password", oldPassword);
    formData.append("new_password", password);
    const body = formData;

    let url = api_url + "profile/password/update";

    try {
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      // console.log(response);
      if (response && response.status == 200) {
        setPreloader(false);

        let data = response.data;
        if (data.status == 200) {
          let message = data.message;
          setMessage(message);

          // navigate("/student/services");
        } else {
          setErrors(data.errors);
          setValidationErros(true);
        }
      } else {
        console.log("API CALLING FAILED");
      }
    } catch (error) {
      if (
        (error.response && error.response.status === 401) ||
        error.response.status === 403 ||
        error.response.status === 500
      ) {
        // Handle token expiry or invalid token
        console.log("Error: Unauthorized - Token expired or invalid");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      } else {
        console.log("Error fetching leads:", error.message);
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
      setPreloader(false);
    }
  }

  return (
    <>
      {!preloader ? (
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="page-header">
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/settings">Settings</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/update-password">Password</Link>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="account-settings-container layout-top-spacing">
              <div className="account-content">
                <div
                  className="scrollspy-example"
                  data-spy="scroll"
                  data-target="#account-settings-scroll"
                  data-offset={-100}
                >
                  <div className="row">
                    <div className="col-md-8 mx-auto layout-spacing">
                      <div className="card">
                        <div className="card-header bg-white border-0">
                          <h4>Update Old Password</h4>
                        </div>

                        <div className="card-body px-0 py-0">
                          {/* display alerts */}
                          {validationErrors ? (
                            <div
                              className="alert alert-danger alert-dismissible mx-4"
                              role="alert"
                            >
                              <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                              <ul className="mb-0">
                                {errors.map((error) => {
                                  return (
                                    <li key={error.id} className="list">
                                      <span className="repo-text">
                                        {error}{" "}
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : (
                            <></>
                          )}
                          {message ? (
                            <div
                              className="alert alert-success alert-dismissible mx-4"
                              role="alert"
                            >
                              <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                              <h6 className="mb-0">{message}</h6>
                            </div>
                          ) : (
                            <></>
                          )}

                          {/* update form */}
                          <form onSubmit={updatePassword}>
                            <div className="modal-body px-4 pb-0">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 mb-3">
                                  <div className="form-group">
                                    <label htmlFor="oldPassword">
                                      Old Password <sup>*</sup>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="oldPassword"
                                      name="oldPassword"
                                      placeholder=""
                                      required
                                      onChange={(e) =>
                                        setOldPassword(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-3">
                                  <div className="form-group">
                                    <label htmlFor="password">
                                      New Password <sup>*</sup>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      name="password"
                                      placeholder=""
                                      required
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer border-0 px-4 pt-0">
                              <button type="submit" className="btn btn-primary">
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img
          className="d-flex mx-auto"
          style={{ marginTop: "125px" }}
          src={require("../../../src/assets/preloaders/loader2.gif")}
          width="50%"
          height="auto"
        />
      )}
    </>
  );
}

export default Password;
