import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./signout.css";
import { api_url } from "../../../config";

function SignOut({ updateLoginStatus }) {
  // USE-STATE HOOKS FOR MANAGING PASSWORD COMPONENT
  const [preloader, setPreloader] = useState(false);

  // useNavigate hook for redirect after login
  const navigate = useNavigate();

  useEffect(() => {
    logoutUser();
  }, []);

  /*
  |========================================================
  | LOGOUT USER -- API CALLING
  |========================================================
  */
  async function logoutUser() {
    setPreloader(true);

    const token = localStorage.getItem("token");
    // console.log(token);

    if (!token) {
      navigate("/login");
      return;
    }

    const body = "";

    //  END-POINT
    let url = api_url + "auth/logout";

      
    try{
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      // console.log(response);
      if (response && response.status == 200) {
        updateLoginStatus(true);
        localStorage.clear();
        // setIsLogin(false);

        setPreloader(false);
        navigate("/login");
        window.location.reload();
      } else {
        console.log("API CALLING FAILED");
        navigate("/login");
        return;
      }

    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403 || error.response.status === 500) {
        // Handle token expiry or invalid token
        console.log("Error: Unauthorized - Token expired or invalid");
        localStorage.clear();
        navigate("/login");
        window.location.reload();

      } else {
        console.log("Error fetching leads:", error.message);
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
      setPreloader(false);
    }

  }
  return (
    <>
      {!preloader ? (
        <div className="container py-5">
          <div className="row">
            <div className="col-md-3 mx-auto">Logged Out Successfully</div>
          </div>
        </div>
      ) : (
        <img
          className="d-flex mx-auto"
          style={{ marginTop: "125px" }}
          src={require("../../../assets/preloaders/loader2.gif")}
          width="50%"
          height="auto"
        />
      )}
    </>
  );
}

export default SignOut;
