import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Button } from "react-bootstrap";

const LeadTooltip2 = React.forwardRef(({ lead, showTooltip, onClick }, ref) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      show={showTooltip}
      overlay={
        <Tooltip>
          <div
            style={{
              textAlign: "left",
              fontSize: "12px",
              padding: "8px",
              borderRadius: "10px",
            }}
          >
            <>
              <p className="mb-0 text-white">Name: {lead.name}</p><br/>
              <p className="mb-0 text-white">Email: <br/>{lead.email}<br/>{lead.email_2}</p><br/>
              <p className="mb-0 text-white">Phone: <br/>{lead.cell_1}<br/>{lead.cell_2}</p><br/>
              <p className="mb-0 text-white">City: {lead.city}</p>
              <p className="mb-0 text-white">Country: {lead.locality}</p>
              <p className="mb-0 text-white">Company Name: {lead.company}</p>
              <Button
                className="py-0 mt-2"
                variant="primary"
                size="sm"
                onClick={() =>
                  copyToClipboard(
                    ` Name:${lead.name}  \n Email:${lead.email} \n ${lead.email_2}  \n Phone:${lead.cell_1} \n ${lead.cell_2} \n City:${lead.city} \n Country:${lead.locality} \n Company Name:${lead.company}`
                  )
                }
              >
                Copy
              </Button>
            </>
          </div>
        </Tooltip>
      }
    >
      <div
        ref={ref}
        className="d-flex align-items-center justify-content-start"
        onClick={onClick}
      >
        <i className="fas fa-info-circle"></i>
        <span className="ml-2">{lead.name}</span>
      </div>
    </OverlayTrigger>
  );
});

export default LeadTooltip2;
