import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import SignIn from "./components/Auth/Signin/SignIn";
import Otp from "./components/Auth/Otp/Otp";

import Header from "./components/Master/Header/Header";
import Topbar from "./components/Master/Topbar/Topbar";
import Footer from "./components/Master/Footer/Footer";

import Dashboard from "./Pages/Dashboard/Dashboard";
import Settings from "./Pages/Setting/Settings";
import Password from "./Pages/Password/Password";
import Staff from "./Pages/Staff/Staff";
import Project from "./Pages/Project/Project";
import Lead from "./Pages/Lead/Lead";
import Status from "./Pages/Status/Status";
import ForgetPassword from "./components/Auth/ForgetPassword/ForgetPassword";
import Logout from "./components/Auth/Logout/SignOut";
import Todo from "./Pages/Todo/Todo";
import Report from "./Pages/Report/Report";
import ResetPassword from "./components/Auth/ResetPassword/ResetPassword";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


function App() {
  const [isLogin, setIsLogin] = useState(localStorage.getItem("isLogin"));

  // Function to update statuses after new record creation
  function updateLoginStatus() {
    setIsLogin(false);
  }

  return (
    <>
      <BrowserRouter>
        <Header />
        {isLogin ? <Topbar /> : null}

        <Routes>
          <Route>
            {/* AUTH ROUTES */}
            <Route path="/" element={<SignIn />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/verify-otp" element={<Otp />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/logout"
              element={<Logout updateLoginStatus={updateLoginStatus} />}
            />

            {/* MODULES */}
            {isLogin ? (
              <>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/staff" element={<Staff />} />
                <Route path="/projects" element={<Project />} />
                <Route path="/leads" element={<Lead />} />
                <Route path="/todo/:date?" element={<Todo />} />
                <Route path="/tasks" element={<Dashboard />} />
                <Route path="/statuses" element={<Status />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/update-password" element={<Password />} />
                <Route path="/reports" element={<Report />} />
                <Route path="/test" element={<Dashboard />} />
              </>
            ) : null}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
