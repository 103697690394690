import React from "react"; 
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Button } from 'react-bootstrap';
 
  const TodoTooltip2 = React.forwardRef(({ todo, showTooltip, onClick }, ref) => {
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
    };
  

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      show={showTooltip}
      overlay={
        <Tooltip>
          <div
            style={{
              textAlign: "left",
              fontSize: "12px",
              padding: "8px",
              borderRadius: "10px",
            }}
          >
            {todo.lead ? (
              <>
                <p className="mb-0 text-white">Name: {todo.lead.name}</p><br/>
                <p className="mb-0 text-white">Email:<br/>{todo.lead.email}<br/>{todo.lead.email_2}</p><br/> 
                <p className="mb-0 text-white">Cell: <br/>{todo.lead.cell_1}<br/>{todo.lead.cell_2}</p><br/> 
                <p className="mb-0 text-white">City: {todo.lead.city}</p>
                <p className="mb-0 text-white">Country: {todo.lead.locality}</p>
                <p className="mb-0 text-white">Company Name: {todo.lead.company}</p>
                <Button className="py-0 mt-2" variant="primary" size="sm" 
                  onClick={() =>
                    copyToClipboard(
                      ` Name:${todo.lead.name}  \n Email:${todo.lead.email} \n ${todo.lead.email_2}  \n Phone:${todo.lead.cell_1} \n ${todo.lead.cell_2} \n City:${todo.lead.city} \n Country:${todo.lead.locality} \n Company Name:${todo.lead.company}`
                    )
                  }
                  >Copy</Button>
              </>
            ) : (
              <p className="text-danger">Not Assigned Yet</p>
            )}
          </div>
        </Tooltip>
      }
    >
      <div  ref={ref}
        className="d-flex align-items-center justify-content-start"
        onClick={onClick}
      >
        <i className="fas fa-info-circle"></i>
        <span className="ml-2">
          {todo.lead ? todo.lead.name : "Not Assigned Yet"}
        </span>
      </div>
    </OverlayTrigger>
  );
});

export default TodoTooltip2;