import React from 'react'

function StatCard({title, value}) {
return (
<>
    <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
        <div className="widget widget-chart-two">
            <div className="widget-heading">
                <h5 className="text-uppercase">{title}</h5>
                <h3 className='mb-0'><b>{value}</b></h3>
            </div>
        </div>
    </div>
</>
)
}

export default StatCard
