import { Link, useParams, useNavigate } from "react-router-dom";
import StatCard from "../../components/Card/Stat/StatCard";
import LeadModal from "../../components/Modal/LeadModal";

import React, { useState, useEffect , useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";
import TaskModal from "../../components/Modal/TaskModal";
import TasksListModal from "../../components/Modal/TasksListModal";

import moment from "moment";
import { data } from "jquery";
import "@fortawesome/fontawesome-free/css/all.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Button } from "react-bootstrap";
import TodoTooltip from "./TodoTooltip";
import TodoTooltip2 from "./TodoTooltip2";

function Todo() {
  const [action, setAction] = useState("create");
  const [leadId, setLeadId] = useState(null);
  const [actor, setActor] = useState(" ");
  let user = JSON.parse(localStorage.getItem("user"));

  const [preloader, setPreloader] = useState(true);
  const [todos, setTodos] = useState([]);
  const [stats, setStats] = useState([]);

  const [showTooltip, setShowTooltip] = useState(false);

  // useNavigate hook for redirect after login
  const navigate = useNavigate();

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // .then(() => alert('Copied to clipboard'))
    // .catch((error) => console.error('Error copying to clipboard:', error));
  };

  // Todo Filters
  const [staff, setStaff] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [projects, setProjects] = useState([]);

  const [status, setStatus] = useState(" ");
  const [staffId, setStaffId] = useState(" ");
  const [projectId, setProjectId] = useState(" ");
  const [deadline, setDeadline] = useState(" ");

  const location = useLocation();
  const path = location.pathname;
  let { date } = useParams();
  if (!date) {
    date = "today";
  }

  const token = localStorage.getItem("token");
  const tooltipRef = useRef(null);
  const tooltipRef2 = useRef(null);
  useEffect(() => {
    getTodos();

    const role = localStorage.getItem("role");
    setActor(role);

    const handleClickOutside = (event) => {  
      // Function to check if the event.target is inside an element with the class 'tooltip'
  const isInsideTooltip = (element) => {
    while (element) {
      if (element.classList && element.classList.contains('tooltip')) {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  };

  const insideTooltip = isInsideTooltip(event.target);
  if (insideTooltip) { 
  } else {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setActiveTooltip(null); 
    }
    if (tooltipRef2.current && !tooltipRef2.current.contains(event.target)) {
   setActiveTooltip2(null);
    }
  }


     
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [date]);
 

  // get todo list
  async function getTodos(e) {
    setPreloader(true);

    // bind filers in todo listing api
    const params = {
      status: status,
      user_id: staffId,
      project_id: projectId,
      deadline: deadline,
    };

    let url = api_url + "todos/" + date;

    try {
      const response = await axios.get(url, {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      // console.log(response);
      if (response && response.status == 200) {
        setPreloader(false);

        console.log(response.data);

        let todos = response.data.todos;
        let stats = response.data.stats;

        // filters data
        let staff = response.data.staff;
        let statuses = response.data.statuses;
        let projects = response.data.projects;

        setTodos(todos);
        setStats(stats);
        setStaff(staff);
        setStatuses(statuses);
        setProjects(projects);
      } else {
        console.log("something went wrong");
      }
    } catch (error) {
      if (
        (error.response && error.response.status === 401) ||
        error.response.status === 403 ||
        error.response.status === 500
      ) {
        // Handle token expiry or invalid token
        console.log("Error: Unauthorized - Token expired or invalid");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      } else {
        console.log("Error fetching leads:", error.message);
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
      setPreloader(false);
    }
  }

  function openTaskModal(lead) {
    console.log("Open Task Modal");
    console.log(lead);

    setLeadId(lead);
    setAction("create");
  }

  // Function to delete task
  async function deleteTask(id) {
    let url = api_url + `task/${id}`;

    setPreloader(true);

    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      if (response && response.status === 200) {
        console.log("Task deleted successfully");

        // Update statuses after deletion
        getTodos();
      } else {
        console.log("Error deleting status");
      }

      setPreloader(false);
    } catch (error) {
      console.log("Error deleting status:", error);
    }
  }

  const [activeTooltip, setActiveTooltip] = useState(null);
  const [activeTooltip2, setActiveTooltip2] = useState(null);

  function handleTooltipClick(id) {
    if (activeTooltip === id) {
      setActiveTooltip(null);
    } else {
      setActiveTooltip(id);
    }
  }

  function handleTooltipClick2(id) {
    if (activeTooltip2 === id) {
      setActiveTooltip2(null);
    } else {
      setActiveTooltip2(id);
    }
  }

  const getImageSrc = (classification) => {
    if (classification === 'urgent') {
      return require('../../../src/assets/images/high.png');
    } else if (classification === 'moderate') {
      return require('../../../src/assets/images/medium.png');
    } else if (classification === 'low') {
      return require('../../../src/assets/images/low.png');
    }
  };

  return (
    <>
      {!preloader ? (
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="page-header mb-3">
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/todo">Todo</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    List
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row layout-spacing">
              <div className="col-lg-12">
                <div className="statbox widget box box-shadow">
                  <div className="widget-content widget-content-area">
                    <h4 className="mb-3">
                      <strong>Tasks to do</strong>
                    </h4>
                    <form onSubmit={getTodos}>
                      {/* filters */}
                      <div className="row bg-light-dark pt-3 mx-0 mb-3">
                        <div className="col-lg-2 col-md-3 px-1">
                          <div className="form-group">
                            <label htmlFor="status">Task Type</label>
                            <select
                              className="form-control"
                              id="status"
                              name="status"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="">Please Select</option>
                              {statuses.map((status) => (
                                <option key={status.id} value={status.title}>
                                  {status.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-3 px-1">
                          <div className="form-group">
                            <label htmlFor="staffId">Staff</label>
                            <select
                              className="form-control"
                              id="staffId"
                              name="staffId"
                              value={staffId}
                              onChange={(e) => setStaffId(e.target.value)}
                            >
                              <option value="">Please Select</option>
                              {staff.map((staf) => (
                                <option key={staf.id} value={staf.id}>
                                  {staf.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-3 px-1">
                          <div className="form-group">
                            <label htmlFor="projectId">Project</label>
                            <select
                              className="form-control"
                              id="projectId"
                              name="projectId"
                              value={projectId}
                              onChange={(e) => setProjectId(e.target.value)}
                            >
                              <option value="">Please Select</option>
                              {projects.map((project) => (
                                <option key={project.id} value={project.id}>
                                  {project.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-3 px-1">
                          <div className="form-group">
                            <label htmlFor="deadline">Deadline</label>
                            <input
                              type="date"
                              className="form-control mb-4"
                              id="deadline"
                              name="deadline"
                              onChange={(e) => setDeadline(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-3 px-1">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "35px" }}
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </form>
                    <hr />

                    <div className="row mx-0">
                      <ul className="list-unstyled  mb-0">
                        <li
                          className="menu single-menu d-inline-block"
                          style={{
                            padding: "10px 20px",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          <Link
                            to="/todo/overdue"
                            style={{
                              fontWeight: 700,
                              color: date === "overdue" ? "#ff8a40" : "inherit",
                            }}
                          >
                            Overdue ({stats.overdue})
                          </Link>
                        </li>
                        <li
                          className="menu single-menu d-inline-block"
                          style={{
                            padding: "10px 20px",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          <Link
                            to="/todo/today"
                            style={{
                              fontWeight: 700,
                              color: date === "today" ? "#ff8a40" : "inherit",
                            }}
                          >
                            Today ({stats.today})
                          </Link>
                        </li>
                        <li
                          className="menu single-menu d-inline-block"
                          style={{
                            padding: "10px 20px",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          <Link
                            to="/todo/tomorrow"
                            style={{
                              fontWeight: 700,
                              color:
                                date === "tomorrow" ? "#ff8a40" : "inherit",
                            }}
                          >
                            Tomorrow ({stats.tomorrow})
                          </Link>
                        </li>
                        <li
                          className="menu single-menu d-inline-block"
                          style={{
                            padding: "10px 20px",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          <Link
                            to="/todo/week"
                            style={{
                              fontWeight: 700,
                              color: date === "week" ? "#ff8a40" : "inherit",
                            }}
                          >
                            7 Days ({stats.week})
                          </Link>
                        </li>
                        <li
                          className="menu single-menu d-inline-block"
                          style={{
                            padding: "10px 20px",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          <Link
                            to="/todo/all"
                            style={{
                              fontWeight: 700,
                              color: date === "all" ? "#ff8a40" : "inherit",
                            }}
                          >
                            All ({stats.all})
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <hr />

                    <table
                      id="style-3"
                      className="table style-3 table-hover table-responsive"
                    >
                      <thead>
                        <tr>
                          <th width="15%">Task</th>
                          <th width="15%">Assigned To</th>
                          <th width="15%">Deadline</th>
                          <th width="10%">Client</th>
                          <th width="10%">ID</th>
                          <th width="10%">Project</th>
                          <th width="5%">Classification</th>
                          <th width="5%" className="text-center dt-no-sorting">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {todos.map((todo, index) => {
                          return (
                            <tr key={todo.id} className="text-capitalize">
                              <td className="checkbox-column text-capitalize">
                                <i className="fas fa-arrow-right"></i>{" "}
                                {todo.next_task}
                              </td>
                              <td> 
                                <TodoTooltip
                                  todo={todo}
                                  showTooltip={activeTooltip === todo.id}
                                  onClick={() => handleTooltipClick(todo.id)}
                                  ref={tooltipRef}
                                />
                              </td>
                              <td>
                                {moment(todo.deadline).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                <TodoTooltip2
                                  todo={todo}
                                  showTooltip={activeTooltip2 === todo.id}
                                  onClick={() => handleTooltipClick2(todo.id)}
                                  ref={tooltipRef2}
                                />
                              </td>
                              <td>
                                {todo.lead
                                  ? todo.lead.lead_lid
                                  : "Not Specified"}{" "}
                                <br />
                                <span className="text-muted">
                                  <small>
                                    {moment(todo.lead.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </small>
                                </span>
                              </td>
                              <td>
                                {todo.project
                                  ? todo.project.title
                                  : "Not Specified"}{" "}
                                {/* <br />
                                <span className="text-muted">
                                  <small>{todo.classification}</small>
                                </span> */}
                              </td>
                              <td>
                                {todo.classification && (
                                  <img
                                    className="d-flex mx-auto"
                                    src={getImageSrc(todo.classification)}
                                    alt={todo.classification}
                                    width="45px"
                                    height="auto"
                                  />
                                )}
                              </td>
                              <td className="text-center">
                                <ul className="table-controls">
                                  <li className="px-2">
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-placement="top"
                                      title
                                      data-original-title="Add"
                                      data-toggle="modal"
                                      data-target=".tasksList"
                                      onClick={() => openTaskModal(todo.lead)}
                                    >
                                      <svg
                                        clip-rule="evenodd"
                                        fill-rule="evenodd"
                                        stroke-linejoin="round"
                                        stroke-miterlimit="2"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-16.5.5h15v15h-15zm13.5 10.75c0-.414-.336-.75-.75-.75h-4.5c-.414 0-.75.336-.75.75s.336.75.75.75h4.5c.414 0 .75-.336.75-.75zm-11.772-.537 1.25 1.114c.13.116.293.173.455.173.185 0 .37-.075.504-.222l2.116-2.313c.12-.131.179-.296.179-.459 0-.375-.303-.682-.684-.682-.185 0-.368.074-.504.221l-1.66 1.815-.746-.665c-.131-.116-.293-.173-.455-.173-.379 0-.683.307-.683.682 0 .188.077.374.228.509zm11.772-2.711c0-.414-.336-.75-.75-.75h-4.5c-.414 0-.75.336-.75.75s.336.75.75.75h4.5c.414 0 .75-.336.75-.75zm-11.772-1.613 1.25 1.114c.13.116.293.173.455.173.185 0 .37-.074.504-.221l2.116-2.313c.12-.131.179-.296.179-.46 0-.374-.303-.682-.684-.682-.185 0-.368.074-.504.221l-1.66 1.815-.746-.664c-.131-.116-.293-.173-.455-.173-.379 0-.683.306-.683.682 0 .187.077.374.228.509zm11.772-1.639c0-.414-.336-.75-.75-.75h-4.5c-.414 0-.75.336-.75.75s.336.75.75.75h4.5c.414 0 .75-.336.75-.75z" />
                                      </svg>
                                    </a>
                                  </li>

                                  <li className="px-2">
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-placement="top"
                                      title
                                      data-original-title="Add"
                                      data-toggle="modal"
                                      data-target=".addTask"
                                      onClick={() => openTaskModal(todo.lead)}
                                    >
                                      <svg
                                        clip-rule="evenodd"
                                        fill-rule="evenodd"
                                        stroke-linejoin="round"
                                        stroke-miterlimit="2"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm0 1.5c-4.69 0-8.497 3.808-8.497 8.498s3.807 8.497 8.497 8.497 8.498-3.807 8.498-8.497-3.808-8.498-8.498-8.498zm-.747 7.75h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"
                                          fill-rule="nonzero"
                                        />
                                      </svg>
                                    </a>
                                  </li>

                                  {/* <li className="px-2">
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit Task"
                                      data-original-title="Edit"
                                      // onClick={() => openModal(lead)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-edit-2 p-1 br-6 mb-1"
                                      >
                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                      </svg>
                                    </a>
                                  </li> */}

                                  {/* <li className="px-2">
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete Task"
                                      data-original-title="Delete"
                                      onClick={() => deleteTask(todo.id)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-trash p-1 br-6 mb-1"
                                      >
                                        <polyline points="3 6 5 6 21 6" />
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                      </svg>
                                    </a>
                                  </li> */}
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img
          className="d-flex mx-auto"
          style={{ marginTop: "125px" }}
          src={require("../../../src/assets/preloaders/loader2.gif")}
          width="50%"
          height="auto"
        />
      )}

      <TaskModal
        action="create"
        tasksData={[projects, statuses]}
        leadId={leadId}
      />
      <TasksListModal leadId={leadId} />
    </>
  );
}

export default Todo;
