import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../Signin/signin.css";
import { api_url } from "../../../config";

function ResetPassword() {
  const [password, setPassword] = useState(" ");
  const [preloader, setPreloader] = useState(false);
  const [validationErrors, setValidationErros] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  // useNavigate hook for redirect after login
  const navigate = useNavigate();

  // get token from local storage
  const token = localStorage.getItem("token");

  // login form submit event handler
  const resetPassword = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("password", password);

    let url = api_url + "password/reset";
    const body = formData;

    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    // console.log(response);
    // return;

    if (response && response.status == 200) {
      setPreloader(false);

      const data = response.data;
      if (data.status == 200) {

        navigate("/login");
      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }
    } else {
      console.log("API CALLING FAILED");
    }
  };

  return (
    <>
      {!preloader ? (
        <section className="signin-form py-5">
          <div className="container mt-5">
            <div className="row">
              <div className="col-12 col-md-6 mx-auto">
                <div
                  className="card signin-card"
                  style={{ borderRadius: "1rem" }}
                >
                  <div className="card-header bg-white border-0 pt-5">
                    <img src="/logo.svg" className="w-50 mx-auto d-block" />
                  </div>

                  <div className="modal-body py-0">
                    {/* display alerts */}
                    {validationErrors ? (
                      <div
                        className="alert alert-danger alert-dismissible m-2"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <ul className="mb-0">
                          {errors.map((error) => {
                            return (
                              <li key={error.id} className="list">
                                <span className="repo-text">{error} </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      <></>
                    )}
                    {message ? (
                      <div
                        className="alert alert-success alert-dismissible m-2"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <h6 className="mb-0">{message}</h6>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="card-body p-4 p-lg-5 text-black">
                    <h6>Setup New Password</h6>

                    <form onSubmit={resetPassword}>
                      <div className="d-flex align-items-center mb-3 pb-0">
                        <span className="mb-2">
                          Please enter your new password below.
                        </span>
                      </div>

                      <div className="mb-2">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          id="password"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control 
                            "
                        />
                      </div>

                      <div className="pt-1 mb-2">
                        <button
                          className="btn btn-dark border-0 w-100"
                          style={{ backgroundColor: "#0D1A59" }}
                        >
                          Submit
                        </button>
                      </div>

                      <p className="mt-3" style={{ color: "#393f81" }}>
                        Go back to login screen{" "}
                        <Link to="/login" style={{ color: "#393f81" }}>
                          <b>Login</b>
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <img
          src={require("../../../assets/preloaders/loader2.gif")}
          width="100%"
          height="auto"
        />
      )}
    </>
  );
}

export default ResetPassword;
