import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";

function LeadModal({ action, updateLeads, leadsData, leadId }) {
  // USE-STATE HOOKS FOR MANAGING SERVICE MODEL
  const [serverError, setServerError] = useState(false);
  const [preloader, setPreloader] = useState(false);
  const [validationErrors, setValidationErros] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  // Leads Inputs
  const [projectId, setProjectId] = useState([]);
  const [staffId, setStaffId] = useState([]);
  const [statusId, setStatusId] = useState([]);
  const [company, setCompany] = useState(" ");
  const [name, setName] = useState(" ");
  const [email, setEmail] = useState(" ");
  const [email2, setEmail2] = useState(" ");
  const [cell1, setCell1] = useState(" ");
  const [cell2, setCell2] = useState(" ");
  const [city, setCity] = useState(" ");
  const [locality, setLocality] = useState(" ");
  const [notes, setNotes] = useState(" ");
  const [source, setSource] = useState(" ");
  const [sourceDescription, setSourceDescription] = useState(" ");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (leadId) {
      getStaff(leadId);
      setMessage("");
      setErrors([]);
    } else {
      setProjectId([]);
      setStaffId([]);
      setStatusId([]);
      setCompany(" ");
      setName(" ");
      setEmail(" ");
      setEmail2(" ");
      setCell1(" ");
      setCell2(" ");
      setCity(" ");
      setLocality(" ");
      setNotes(" ");
      setSource(" ");
      setSourceDescription(" ");
      setIsActive(" ");
      setMessage("");
      setErrors([]);
    }
  }, [leadId]);

  /*
|========================================================
| Get lead detail -> Edit Record
|========================================================
*/
  async function getStaff(record) { 
    try {
      document.getElementById('notes').value = "";
      setProjectId(record.project_id);
      setStaffId(record.user_id);
      setStatusId(record.status_id);
      setCompany(record.company);
      setName(record.name);
      setEmail(record.email);
      setEmail2(record.email_2);
      setCell1(record.cell_1);
      setCell2(record.cell_2);
      setCity(record.city);
      setLocality(record.locality);
      setNotes(record.notes);
      setSource(record.source);
      setSourceDescription(record.source_description);
      setIsActive(record.status);
    } catch (error) {
      console.log("Error fetching status:", error);
    }
  }

  /*
|========================================================
| Add New Project -- API CALLING
|========================================================
*/
  async function storeLead(e) {
    e.preventDefault();

    setPreloader(true);

    // get token from local storage
    const token = localStorage.getItem("token");
    // console.log(token);

    // MAKE FORMDATA FOR CALLING STORE NEW SERVER API
    const formData = new FormData();
    formData.append("project_id", projectId);
    formData.append("user_id", staffId);
    formData.append("status_id", statusId);
    formData.append("company", company);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("email_2", email2);
    formData.append("cell_1", cell1);
    formData.append("cell_2", cell2);
    formData.append("city", city);
    formData.append("locality", locality);
    formData.append("notes", notes);
    formData.append("source", source);
    formData.append("source_description", sourceDescription);
    formData.append("status", isActive);
    const body = formData;

    let url = "";
    // Create/Update END-POINT
    if (action == "create") {
      url = api_url + "lead/store";
    } else {
      url = api_url + "lead/" + leadId.id;
    }

    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
    console.log(response);
    // console.log(response);
    if (response && response.status == 200) {
      setPreloader(false);

      console.log(response.data);

      let data = response.data;
      if (data.status == 200) {
        updateLeads(true);
        let message = data.message;
        setMessage(message);
        setSuccessMessage(true);

        window.location.reload();

        // navigate("/student/services");
      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }
    } else {
      console.log("API CALLING FAILED");
    }
  }

  return (
    <div>
      <div
        className="modal fade"
        id="leadModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Lead Information 
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
            </div>

            <div className="modal-body py-0">
              {/* display alerts */}
              {validationErrors ? (
                <div
                  className="alert alert-danger alert-dismissible m-2"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <ul className="mb-0">
                    {errors.map((error) => {
                      return (
                        <li key={error.id} className="list">
                          <span className="repo-text">{error} </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <></>
              )}
              {message ? (
                <div
                  className="alert alert-success alert-dismissible m-2"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h6 className="mb-0">{message}</h6>
                </div>
              ) : (
                <></>
              )}
            </div>

            <form onSubmit={storeLead}>
              <div className="modal-body p-4">
                <div className="row">
                  <h6 className="col-12">GENERAL INFORMATION</h6>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="projectId">Project</label>
                      <select
                        className="form-control"
                        id="projectId"
                        name="projectId"
                        value={projectId}
                        onChange={(e) => setProjectId(e.target.value)}
                      >
                        <option>Assign Project</option>
                        {leadsData[0].map((project) => (
                          <option key={project.id} value={project.id}>
                            {project.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="staffId">Staff </label>
                      <select
                        className="form-control"
                        id="staffId"
                        name="staffId"
                        value={staffId}
                        onChange={(e) => setStaffId(e.target.value)}
                         
                      >
                        <option>Assign Staff</option>
                        {leadsData[1].map((staff) => (
                          <option key={staff.id} value={staff.id}>
                            {staff.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="statusId">Status <sup>*</sup></label>
                      <select
                        className="form-control"
                        id="statusId"
                        name="statusId"
                        value={statusId}
                        onChange={(e) => setStatusId(e.target.value)}
                        required
                      >
                        <option>Set Lead Status</option>
                        {leadsData[2].map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="company">
                        Company
                      </label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="company"
                        name="company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <h6 className="col-12 pt-3">CUSTOMER INFORMATION</h6>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="name">
                        Name 
                      </label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="email">
                        Email 
                      </label>
                      <input
                        type="email"
                        className="form-control mb-4"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="email2">
                        Email 2
                      </label>
                      <input
                        type="email"
                        className="form-control mb-4"
                        id="email2"
                        name="email2"
                        value={email2}
                        onChange={(e) => setEmail2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="cell1">
                        Cell # 1 
                      </label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="cell1"
                        name="cell1"
                        value={cell1}
                        placeholder="Cell 1"
                        onChange={(e) => setCell1(e.target.value)}
                        
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="cell2">
                        Cell # 2
                      </label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="cell2"
                        name="cell2"
                        value={cell2}
                        onChange={(e) => setCell2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="city"
                        name="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="locality">Locality</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="locality"
                        name="locality"
                        value={locality}
                        onChange={(e) => setLocality(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group">
                      <label htmlFor="notes">Note:</label>
                      <textarea
                        type="text"
                        className="form-control mb-4"
                        id="notes"
                        name="notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      >{notes}</textarea>
                    </div>
                  </div>

                  <hr />
                  <h6 className="col-12 pt-3">SOURCE INFORMATION</h6>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="source">Source</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="source"
                        name="source"
                        value={source}
                        onChange={(e) => setSource(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="sourceDescription">Source Detail</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="sourceDescription"
                        name="sourceDescription"
                        value={sourceDescription}
                        onChange={(e) => setSourceDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn" data-dismiss="modal">
                  <i className="flaticon-cancel-12" />
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadModal;
