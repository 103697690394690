import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";
import moment from "moment-timezone";

function TaskModal({ action, tasksData, leadId }) {
  // USE-STATE HOOKS FOR MANAGING SERVICE MODEL
  const [serverError, setServerError] = useState(false);
  const [preloader, setPreloader] = useState(false);
  const [validationErrors, setValidationErros] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  // Leads Inputs
  const [currentStatus, setCurrentStatus] = useState([null]);
  const [type, setType] = useState(" ");
  const [subType, setSubType] = useState(" ");
  const [completedDate, setCompletedDate] = useState(" ");
  const [attachment, setAttachment] = useState(" ");
  const [comment, setComment] = useState(" ");
  const [nextTask, setNextTask] = useState(" "); 
  const [projectId, setProjectId] = useState(leadId ? leadId.project_id : null);
  const [deadline, setDeadline] = useState(" ");
  const [classification, setClassification] = useState(" ");
  const [isActive, setIsActive] = useState(false);

  // Get today's date in YYYY-MM-DD format for Toronto timezone
  const getTodayDateInToronto = () => {
    const todayDate = new Date().toLocaleString("en-US", {
      timeZone: "America/Toronto",
    });
    const date = new Date(todayDate);
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    const todayDate = getTodayDateInToronto();
    setCompletedDate(todayDate);
  }, []);
  // Get today's date in YYYY-MM-DD format
  const today = moment.tz("America/Toronto").format("YYYY-MM-DD");
  // const today = moment.tz('Asia/Karachi').format('YYYY-MM-DD');

  useEffect(() => {
    console.log("Today is " + leadId);
    console.log();
    if (leadId) {
      setProjectId( leadId.project_id );
      setCurrentStatus(leadId.status_id);
      if(leadId.tasks.length > 0 )
        {
          setClassification(leadId.tasks[0].classification);
        }
     
    }
  }, [leadId]);


  const handleAttachment = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setAttachment(reader.result);
    };
    reader.readAsDataURL(file);
  };

  /*
|========================================================
| Add New Task -> Create API
|========================================================
*/
  async function storeTask(e) {
    e.preventDefault();

    setPreloader(true);

    // get token from local storage
    const token = localStorage.getItem("token");
    // console.log(token);

    // MAKE FORMDATA FOR CALLING STORE NEW SERVER API
    const formData = new FormData();
    formData.append("lead_id", leadId.id);
    formData.append("type", type);
    formData.append("sub_type", subType);
    formData.append("completed_date", completedDate);
    formData.append("is_image_base64", "true");
    formData.append("attachment", attachment);
    formData.append("comment", comment);
    formData.append("next_task", nextTask);
    formData.append("project_id", projectId);
    formData.append("deadline", deadline);
    formData.append("classification", classification);
    formData.append("status", isActive);
    const body = formData;

    let url = "";
    // Create/Update END-POINT
    if (action == "create") {
      url = api_url + "task/store";
    } else {
      url = api_url + "task/update";
    }

    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    // console.log(response);
    if (response && response.status == 200) {
      setPreloader(false);

      // console.log(response.data);

      let data = response.data;
      if (data.status == 200) {
        let message = data.message;
        setMessage(message);
        setSuccessMessage(true);
        clearTaskStates();

        window.location.reload();
      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }
    } else {
      console.log("API CALLING FAILED");
    }
  }

  function clearTaskStates() {
    setType(" ");
    setSubType(" ");
    setCompletedDate(" ");
    setAttachment(" ");
    setComment(" ");
    setNextTask(" ");
    setProjectId(" ");
    setDeadline(" ");
    setClassification(" ");
  }

  return (
    <>
      <div
        className="modal fade addTask"
        id=""
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Task
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
            </div>

            <div className="modal-body py-0">
              {/* display alerts */}
              {validationErrors ? (
                <div
                  className="alert alert-danger alert-dismissible m-2"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <ul className="mb-0">
                    {errors.map((error) => {
                      return (
                        <li key={error.id} className="list">
                          <span className="repo-text">{error} </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <></>
              )}
              {message ? (
                <div
                  className="alert alert-success alert-dismissible m-2"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h6 className="mb-0">{message}</h6>
                </div>
              ) : (
                <></>
              )}
            </div>

            <form onSubmit={storeTask}>
              <div
                className="modal-body p-4"
                style={{
                  height: "450px",
                  overflow: "overlay",
                }}
              >
                <h6 className="bg-light-dark text-dark p-3 mb-3">
                  Current Task
                </h6>

                <div className="form-group row mb-2">
                  <div className="col-md-3">
                    <label htmlFor="nextTask" className="mt-2">
                      Current Status <sup>*</sup>
                    </label>
                  </div>
                  <div className="col-md-9">
                    <select
                      className="form-control"
                      id="nextTask"
                      name="nextTask"
                      onChange={(e) => setNextTask(e.target.value)}
                      value={currentStatus}
                      disabled
                    >
                      <option>Please Select</option>
                      {tasksData[1].map((status) => (
                        <option key={status.id} value={status.id}>
                          {status.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <div className="col-md-3">
                    <label htmlFor="type" className="mt-2">
                      Type <sup>*</sup>
                    </label>
                  </div>
                  <div className="col-md-9">
                    <select
                      className="form-control"
                      id="type"
                      name="type"
                      onChange={(e) => setType(e.target.value)}
                      required
                    >
                      <option>Please Select</option>
                      <option value="meetings">Meetings</option>
                      <option value="calls">Calls</option>
                      <option value="emails">Emails</option>
                      <option value="whatsapp">Whatsapp</option>
                      <option value="sales">Sales</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <div className="col-md-3">
                    <label htmlFor="subType" className="mt-2">
                      Sub Type <sup>*</sup>
                    </label>
                  </div>
                  <div className="col-md-9">
                    <select
                      className="form-control"
                      id="subType"
                      name="subType"
                      onChange={(e) => setSubType(e.target.value)}
                      required
                    >
                      <option>Please Select</option>
                      <option value="proposal">Proposal</option>
                      <option value="contacted-client">Contacted Client</option>
                      <option value="followed-up">Followed Up</option>
                      <option value="call-attempt">Call Attempt</option>
                      <option value="not-answering">Not Answering</option>
                      <option value="completed-task">Completed Task</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <label
                    htmlFor="completedDate"
                    className="col-md-3 col-sm-2 col-form-label"
                  >
                    Completed Date <sup>*</sup>
                  </label>
                  <div className="col-md-9 col-sm-10">
                    <input
                      type="date"
                      className="form-control"
                      id="completedDate"
                      name="completedDate"
                      placeholder="Completed Date"
                      min={completedDate}
                      max={completedDate}
                      value={completedDate}
                      readOnly
                      onChange={(e) => setCompletedDate(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <label
                    htmlFor="attachment"
                    className="col-md-3 col-sm-2 col-form-label"
                  >
                    Attachment
                  </label>
                  <div className="col-md-9 col-sm-10">
                    <input
                      type="file"
                      className="form-control"
                      id="attachment"
                      name="attachment"
                      placeholder="Attachment"
                      onChange={handleAttachment}
                    />
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <label
                    htmlFor="comment"
                    className="col-md-3 col-sm-2 col-form-label"
                  >
                    Comment
                  </label>
                  <div className="col-md-9 col-sm-10">
                    <textarea
                      type="text"
                      className="form-control mb-4"
                      id="comment"
                      name="comment"
                      placeholder="Comment"
                      onChange={(e) => setComment(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <h6 className="bg-light-dark text-dark p-3 mb-3">Next Task</h6>

                <div className="form-group row mb-2">
                  <div className="col-md-3">
                    <label htmlFor="nextTask" className="mt-2">
                      Next Task <sup>*</sup>
                    </label>
                  </div>
                  <div className="col-md-9">
                    <select
                      className="form-control"
                      id="nextTask"
                      name="nextTask"
                      onChange={(e) => setNextTask(e.target.value)}
                      required
                    >
                      <option>Please Select</option>
                      {tasksData[1].map((status) => (
                        <option key={status.id} value={status.title}>
                          {status.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <div className="col-md-3">
                    <label htmlFor="projectId" className="mt-2">
                      Project <sup>*</sup>
                    </label>
                  </div>
                  <div className="col-md-9">
                    <select
                      className="form-control"
                      id="projectId"
                      name="projectId"
                      // value={leadId ? leadId.project_id : null}
                      value={projectId}
                      onChange={(e) => setProjectId(e.target.value)}
                      required
                    >
                      <option>Please Select</option>
                      {tasksData[0].map((project) => (
                        <option key={project.id} value={project.id}>
                          {project.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <label
                    htmlFor="deadline"
                    className="col-md-3 col-sm-2 col-form-label"
                  >
                    Deadline <sup>*</sup>
                  </label>
                  <div className="col-md-9 col-sm-10">
                    <input
                      type="date"
                      className="form-control"
                      id="deadline"
                      name="deadline"
                      min={today}
                      placeholder="Deadline"
                      onChange={(e) => setDeadline(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <div className="col-md-3">
                    <label htmlFor="classification" className="mt-2">
                      Classification <sup>*</sup>
                    </label>
                  </div>
                  <div className="col-md-9">
                    <select
                      className="form-control"
                      id="classification"
                      name="classification"
                      onChange={(e) => setClassification(e.target.value)}
                      value={classification}
                      required
                    >
                      <option>Please Select</option>
                      <option value="urgent">Urgent</option>
                      <option value="moderate">Moderate</option>
                      <option value="low">Low</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn" data-dismiss="modal">
                  <i className="flaticon-cancel-12" />
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskModal;
