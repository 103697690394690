import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url, image_url } from "../../config";
import moment from "moment";

function TasksListModal({ leadId }) {
  // USE USE-STATE HOOKS TO MANAGE PAGE CONTENT
  const [actor, setActor] = useState(" ");
  const [tasks, setTasks] = useState([]);
  let user = JSON.parse(localStorage.getItem("user"));

  const location = useLocation();
  const path = location.pathname;
  // console.log("here ...................");
  // console.log(leadId);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (leadId) {
      setTasks(leadId.tasks);
    } else {
      setTasks([]);
    }

    const role = localStorage.getItem("role");
    setActor(role);
  }, [leadId]);

  // console.log(tasks);

  const getImageSrc = (classification) => {
    if (classification === "urgent") {
      return require("../../../src/assets/images/high.png");
    } else if (classification === "moderate") {
      return require("../../../src/assets/images/medium.png");
    } else if (classification === "low") {
      return require("../../../src/assets/images/low.png");
    }
  };

  return (
    <>
      <div
        className="modal fade tasksList"
        id=""
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Lead Tasks
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
            </div>

            <div className="modal-body">
              <table
                id="style-3"
                className="table style-3 table-hover table-responsive"
              >
                <thead>
                  <tr>
                    <th width="5%" className="checkbox-column text-center">
                      Id
                    </th>
                    <th width="10%">Type & SubType</th>
                    <th width="10%">ID</th>
                    <th>Completed Date</th>
                    <th width="25%">Comment</th>
                    <th>Next Task</th>
                    <th>Deadline</th>
                    <th>Classification</th>
                    <th>Status</th>
                    {/* <th width="10%" className="text-center dt-no-sorting">
                      Action
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {tasks && tasks.length > 0 ? (
                    tasks.map((task, index) => (
                      <tr key={task.id} className="text-capitalize">
                        <td className="checkbox-column text-center">
                          {index + 1}
                        </td>
                        <td>
                          <small className="text-muted">Task Type:</small>{" "}
                          {task.type} <br />
                          <small className="text-muted">Sub Type:</small>{" "}
                          {task.sub_type}
                        </td>
                        <td>
                          {leadId ? leadId.lead_lid : "Not Specified"} <br />
                          <span className="text-muted">
                            <small>
                              {moment(leadId.created_at).format("DD-MM-YYYY")}
                            </small>
                          </span>
                        </td>
                        <td>
                          {moment(task.completed_date).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          {task.comment}
                          <br />
                          {task.attachment && (
                            <>
                              {" "}
                              <a
                                href={`${image_url}storage/images/tasks/lg/${task.attachment}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-info"
                              >
                                 Attachment
                              </a>
                            </>
                          )}
                        </td>
                        <td>{task.next_task}</td>
                        <td>{moment(task.deadline).format("DD-MM-YYYY")}</td>
                        {/* <td>{task.classification}</td> */}
                        <td>
                          {task.classification && (
                            <img
                              className="d-flex mx-auto"
                              src={getImageSrc(task.classification)}
                              alt={task.classification}
                              width="45px"
                              height="auto"
                            />
                          )}
                        </td>
                        <td>
                          {task.status ? (
                            <span className="shadow-none badge badge-success">
                              Completed
                            </span>
                          ) : (
                            <span className="shadow-none badge badge-danger">
                              Pending
                            </span>
                          )}
                        </td>
                        {/* <td className="text-center">
                        <span className={`shadow-none badge ${task.status ? "badge-success" : "badge-danger"}`}>
                            {task.status ? "Active" : "Inactive"}
                        </span>
                    </td>
                    <td className="text-center">
                        <ul className="table-controls">
                            <li className="px-3">
                                <a href="javascript:void(0);" className="bs-tooltip" title="Edit">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2 p-1 br-6 mb-1">
                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" className="bs-tooltip" title="Delete">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash p-1 br-6 mb-1">
                                        <polyline points="3 6 5 6 21 6" />
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No tasks created yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TasksListModal;
