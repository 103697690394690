import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";

function Settings() {
  // USE-STATE HOOKS FOR MANAGING SERVICE MODEL
  const [action, setAction] = useState("create");
  const [actor, setActor] = useState(" ");

  const [preloader, setPreloader] = useState(false);
  const [validationErrors, setValidationErros] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  // Staff Inputs
  const [sitename, setSiteName] = useState(" ");
  const [sitePublisher, setSitePublisher] = useState(" ");
  const [about, setAbout] = useState(" ");
  const [name, setName] = useState(" ");
  const [email, setEmail] = useState(" ");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [gender, setGender] = useState(" ");
  const [dob, setDob] = useState(" ");
  const [location, setLocation] = useState("");
  const [region, setRegion] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState("");
  const [favicon, setFavicon] = useState("");

  const [password, setPassword] = useState(" ");

  // useNavigate hook for redirect after login
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const handleDateChange = (event) => {
    const inputValue = event.target.value;
    setDob(inputValue);
  };

  /*
|========================================================
| USE-EFFECT HOOK FOR CALLING API
|========================================================
*/
  useEffect(() => {
    getSiteSettings();

    const role = localStorage.getItem("role");
    setActor(role);
    // getAllSubjects();
  }, []);

  /*
|========================================================
| Edit Site Settings API Calling
|========================================================
*/
  async function getSiteSettings(e) {
    let url = api_url + "settings/edit";

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    // console.log(response);

    if (response && response.status == 200) {
      setPreloader(false);

      // let data = response.data;
      let settings = response.data.settings;
      let user = response.data.user;

      // set profile input values
      setSiteName(settings.site_name);
      setSitePublisher(settings.site_publisher);
      setAbout(settings.about);

      setName(user.name);
      setEmail(user.email);
      setMobile(user.mobile_no);
      setPhone(user.phone_no);
      setWhatsapp(user.whatsapp_no);
      setGender(user.gender);
      setDob(user.date_of_birth);
      setLocation(user.location);
      setRegion(user.region);
      setAddress(user.address);
    } else {
      console.log("something went wrong");
    }
  }

  /*
|========================================================
| Update site settings -- API CALLING
|========================================================
*/
  async function updateSettings(e) {
    e.preventDefault();

    console.log("form Submitted");
    setErrors("");
    setMessage("");

    // setValidationErros(false);
    setPreloader(true);

    // get token from local storage
    const token = localStorage.getItem("token");
    // console.log(token);

    // MAKE FORMDATA FOR CALLING STORE NEW SERVER API
    const formData = new FormData();
    formData.append("site_name", sitename);
    formData.append("site_publisher", sitePublisher);
    formData.append("about", about);

    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile_no", mobile);
    formData.append("phone_no", phone);
    formData.append("whatsapp_no", whatsapp);
    formData.append("gender", gender);
    formData.append("date_of_birth", dob);
    formData.append("location", location);
    formData.append("region", region);
    formData.append("address", address);
    formData.append("logo", logo);
    formData.append("favicon", favicon);
    const body = formData;

    let url = api_url + "settings/update";

    try {
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      // console.log(response);
      if (response && response.status == 200) {
        setPreloader(false);

        console.log(response.data);

        let data = response.data;
        if (data.status == 200) {
          let message = data.message;
          setMessage(message);
          setSuccessMessage(true);

          // navigate("/student/services");
        } else {
          setErrors(data.errors);
          setValidationErros(true);
        }
      } else {
        console.log("API CALLING FAILED");
      }
    } catch (error) {
      if (
        (error.response && error.response.status === 401) ||
        error.response.status === 403 ||
        error.response.status === 500
      ) {
        // Handle token expiry or invalid token
        console.log("Error: Unauthorized - Token expired or invalid");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      } else {
        console.log("Error fetching leads:", error.message);
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
      setPreloader(false);
    }
  }

  return (
    <>
      {!preloader ? (
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="page-header">
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/settings">Settings</Link>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="account-settings-container layout-top-spacing">
              <div className="account-content">
                <div
                  className="scrollspy-example"
                  data-spy="scroll"
                  data-target="#account-settings-scroll"
                  data-offset={-100}
                >
                  <div className="row">
                    <div className="col-md-8 mx-auto layout-spacing">
                      <div className="card">
                        <div className="card-header bg-white border-0">
                          <h4>Settings</h4>
                        </div>

                        <div className="card-body px-0 py-0">
                          {/* display alerts */}
                          {validationErrors ? (
                            <div
                              className="alert alert-danger alert-dismissible mx-4"
                              role="alert"
                            >
                              <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                              <ul className="mb-0">
                                {errors.map((error) => {
                                  return (
                                    <li key={error.id} className="list">
                                      <span className="repo-text">
                                        {error}{" "}
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : (
                            <></>
                          )}
                          {message ? (
                            <div
                              className="alert alert-success alert-dismissible mx-4"
                              role="alert"
                            >
                              <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                              <h6 className="mb-0">{message}</h6>
                            </div>
                          ) : (
                            <></>
                          )}

                          {/* update form */}
                          <form onSubmit={updateSettings}>
                            <div className="modal-body px-4">
                              <div className="row">
                                {actor === "admin" && (
                                  <>
                                    <div className="col-12">
                                      <h6 className="col-12 bg-light py-3 mb-3 rounded">
                                        Site Settings
                                      </h6>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label htmlFor="siteName">
                                          Site Name <sup>*</sup>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control mb-4"
                                          id="siteName"
                                          name="siteName"
                                          value={sitename}
                                          placeholder="Site Name"
                                          required
                                          onChange={(e) =>
                                            setSiteName(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label htmlFor="sitePublisher">
                                          Site Publisher <sup>*</sup>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control mb-4"
                                          id="sitePublisher"
                                          name="sitePublisher"
                                          value={sitePublisher}
                                          placeholder="Site Publisher"
                                          required
                                          onChange={(e) =>
                                            setSitePublisher(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 mb-4">
                                      <div className="form-group">
                                        <label htmlFor="about">About</label>
                                        <textarea
                                          className="form-control mb-4"
                                          id="about"
                                          name="about"
                                          value={about}
                                          placeholder="About"
                                          onChange={(e) =>
                                            setAbout(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}

                                <div className="col-12">
                                  <h6 className="col-12 bg-light py-3 mb-3 rounded">
                                    User Settings
                                  </h6>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="name">
                                      Name <sup>*</sup>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mb-4"
                                      id="name"
                                      name="name"
                                      value={name}
                                      placeholder="Name"
                                      required
                                      onChange={(e) => setName(e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="email">
                                      Email <sup>*</sup>
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control mb-4"
                                      id="email"
                                      name="email"
                                      value={email}
                                      placeholder="Email"
                                      required
                                      readOnly
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                  </div>
                                </div>

                                {/* <div className="col-lg-3 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="email">
                                      Email <sup>*</sup>
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control mb-4"
                                      id="email"
                                      name="email"
                                      value={email}
                                      placeholder="Email"
                                      required
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                  </div>
                                </div> */}

                                {/* <div className="col-lg-3 col-md-6">
                                <div className="form-group">
                                  <label htmlFor="password">
                                    Password <sup>*</sup>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control mb-4"
                                    id="password"
                                    name="password"
                                    value={password}
                                    placeholder="Password"
                                    required
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                </div>
                              </div> */}

                                <div className="col-lg-4 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="mobile">
                                      Mobile No <sup>*</sup>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mb-4"
                                      id="mobile"
                                      name="mobile"
                                      value={mobile}
                                      placeholder="Mobile No"
                                      required
                                      onChange={(e) =>
                                        setMobile(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                {/* <div className="col-lg-4 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="phone">
                                      Phone No <sup>*</sup>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mb-4"
                                      id="phone"
                                      name="phone"
                                      value={phone}
                                      placeholder="Phone No"
                                      required
                                      onChange={(e) => setPhone(e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-4 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="whatsapp">
                                      Whatsapp No <sup>*</sup>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mb-4"
                                      id="whatsapp"
                                      name="whatsapp"
                                      value={whatsapp}
                                      placeholder="Whatsapp No"
                                      required
                                      onChange={(e) =>
                                        setWhatsapp(e.target.value)
                                      }
                                    />
                                  </div>
                                </div> */}

                                <div className="col-lg-4 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="gender">Gender</label>
                                    <select
                                      className="form-control"
                                      id="gender"
                                      name="gender"
                                      value={gender}
                                      onChange={(e) =>
                                        setGender(e.target.value)
                                      }
                                    >
                                      <option>Select Gender</option>
                                      <option value="male">Male</option>
                                      <option value="female">Female</option>
                                      <option value="other">Other</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-4 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="dob">Date of Birth</label>
                                    <input
                                      type="date"
                                      className="form-control mb-4"
                                      id="dob"
                                      name="dob"
                                      value={dob}
                                      onChange={handleDateChange}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <label htmlFor="location">Location</label>
                                    <input
                                      type="text"
                                      className="form-control mb-4"
                                      id="location"
                                      name="location"
                                      value={location}
                                      placeholder="Location"
                                      onChange={(e) =>
                                        setLocation(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <label htmlFor="region">Region</label>
                                    <input
                                      type="text"
                                      className="form-control mb-4"
                                      id="region"
                                      name="region"
                                      value={region}
                                      placeholder="Region"
                                      onChange={(e) =>
                                        setRegion(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="form-group mb-0">
                                    <label htmlFor="address">Address</label>
                                    <textarea
                                      className="form-control mb-0"
                                      id="address"
                                      name="address"
                                      value={address}
                                      placeholder="Address"
                                      onChange={(e) =>
                                        setAddress(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer border-0">
                              <button type="submit" className="btn btn-primary">
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img
          className="d-flex mx-auto"
          style={{ marginTop: "125px" }}
          src={require("../../../src/assets/preloaders/loader2.gif")}
          width="50%"
          height="auto"
        />
      )}
    </>
  );
}

export default Settings;
