import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Button } from "react-bootstrap";

 
  const LeadTooltip = React.forwardRef(({ lead, showTooltip, onClick }, ref) => {
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
    };
    

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      show={showTooltip}
      overlay={
        <Tooltip>
          <div
            style={{
              textAlign: "left",
              fontSize: "12px",
              padding: "8px",
              borderRadius: "10px",
            }}
          >
            {lead.staff ? (
              <>
                <p className="mb-0 text-white">Name: {lead.staff.name}</p>
                <p className="mb-0 text-white">Email: {lead.staff.email}</p>
                <p className="mb-0 text-white">Phone: {lead.staff.mobile_no}</p>
                <Button className="py-0 mt-2" variant="primary" size="sm" 
                onClick={() => copyToClipboard(`Name:${lead.staff.name}\nEmail:${lead.staff.email}\nPhone:${lead.staff.mobile_no}`)}>Copy</Button>
              </>
            ) : (
              <p className="text-danger">Not Assigned Yet</p>
            )}
          </div>
        </Tooltip>
      }
    >
      <div
        ref={ref}
        className="d-flex align-items-center justify-content-start"
        onClick={onClick}
      >
        <i className="fas fa-info-circle"></i>
        <span className="ml-2">
          {lead.staff ? lead.staff.name : "Not Assigned Yet"}
        </span>
      </div>
    </OverlayTrigger>
  );
});

export default LeadTooltip;