import { Link, useParams, useNavigate } from "react-router-dom";
import StatCard from "../../components/Card/Stat/StatCard";
import LeadModal from "../../components/Modal/LeadModal";

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";

import moment from "moment";
import { data } from "jquery";

function Report() {
  const [action, setAction] = useState("create");
  const [leadId, setLeadId] = useState(null);
  const [actor, setActor] = useState(" ");
  let user = JSON.parse(localStorage.getItem("user"));

  const [preloader, setPreloader] = useState(true);
  const [todos, setTodos] = useState([]);
  const [stats, setStats] = useState([]);
  const [results, setResults] = useState([]);
  // Todo Filters
  const [staff, setStaff] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [projects, setProjects] = useState([]);

  const [status, setStatus] = useState(" ");
  const [staffId, setStaffId] = useState(" ");
  const [projectId, setProjectId] = useState(" ");
  const [deadline, setDeadline] = useState(" ");

  // useNavigate hook for redirect after login
  const navigate = useNavigate();

  const location = useLocation();
  const path = location.pathname;
  let { date } = useParams();
  if (!date) {
    date = "today";
  }

  const token = localStorage.getItem("token");

  useEffect(() => {
    getReports();

    const role = localStorage.getItem("role");
    setActor(role);
  }, [date]);

  // get todo list
  async function getReports(e) {
    setPreloader(true);

    // bind filers in todo listing api
    const params = {
      user_id: staffId,
      complete_date: deadline,
      // status: status,
      // project_id: projectId,
    };

    let url = api_url + "reports";

    try {
      const response = await axios.get(url, {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      // console.log(response);
      if (response && response.status == 200) {
        setPreloader(false);

        // console.log(response.data);

        let stats = response.data.stats;
        setStats(stats);
        setResults(response.data.filter_tasks);

        // filters data
        let staff = response.data.staff;
        let statuses = response.data.statuses;
        let projects = response.data.projects;

        setStaff(staff);
        setStatuses(statuses);
        setProjects(projects);
        
      } else {
        console.log("something went wrong");
      }
    } catch (error) {
      if (
        (error.response && error.response.status === 401) ||
        error.response.status === 403 ||
        error.response.status === 500
      ) {
        // Handle token expiry or invalid token
        console.log("Error: Unauthorized - Token expired or invalid");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      } else {
        console.log("Error fetching leads:", error.message);
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
      setPreloader(false);
    }
  }

  
  return (
    <>
      {!preloader ? (
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="page-header mb-3">
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/reports">Reports</Link>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row layout-spacing">
              <div className="col-lg-12">
                <div className="statbox widget box box-shadow">
                  <div className="widget-content widget-content-area">
                    <h4 className="mb-3">
                      <strong>Reports</strong>
                    </h4>
                    <form onSubmit={getReports}>
                      <div className="row bg-light-dark pt-3 mx-0 px-3 mb-3">
                        <div className="col-lg-2 col-md-3 px-1">
                          <div className="form-group">
                            <label htmlFor="staffId">Staff Member</label>
                            <select
                              className="form-control"
                              id="staffId"
                              name="staffId"
                              value={staffId}
                              onChange={(e) => setStaffId(e.target.value)}
                            >
                              <option value="">Please Select</option>
                              {staff.map((staf) => (
                                <option key={staf.id} value={staf.id}>
                                  {staf.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {/* <div className="col-lg-2 col-md-3 px-1">
                          <div className="form-group">
                            <label htmlFor="status">Task Type</label>
                            <select
                              className="form-control"
                              id="status"
                              name="status"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="">Please Select</option>
                              {statuses.map((status) => (
                                <option key={status.id} value={status.title}>
                                  {status.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div> */}

                        {/* <div className="col-lg-2 col-md-3 px-1">
                          <div className="form-group">
                            <label htmlFor="projectId">Project</label>
                            <select
                              className="form-control"
                              id="projectId"
                              name="projectId"
                              value={projectId}
                              onChange={(e) => setProjectId(e.target.value)}
                            >
                              <option value="">Please Select</option>
                              {projects.map((project) => (
                                <option key={project.id} value={project.id}>
                                  {project.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div> */}

                        <div className="col-lg-2 col-md-3 px-1">
                          <div className="form-group">
                            <label htmlFor="deadline">Completed Date</label>
                            <input
                              type="date"
                              className="form-control mb-4"
                              id="deadline"
                              name="deadline"
                              value={deadline}
                              onChange={(e) => setDeadline(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-3 px-1">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "35px" }}
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </form>
                    <hr />

                    <div style={{ display: "none" }} className="row layout-spacing layout-top-spacing">
                      <h6 className="col-12 py-2"><strong>SUMMARY</strong></h6>
                      <StatCard
                        title="Meetings"
                        value={stats["meetings"]}
                        cols="2"
                      />
                      <StatCard title="Calls" value={stats["calls"]} cols="2" />
                      <StatCard
                        title="Emails"
                        value={stats["emails"]}
                        cols="2"
                      />
                      <StatCard
                        title="Whatsapp"
                        value={stats["whatsapp"]}
                        cols="2"
                      />
                      <StatCard title="Sales" value={stats["sales"]} cols="2" />
                      <StatCard
                        title="Proposals"
                        value={stats["proposals"]}
                        cols="2"
                      />
                      <StatCard
                        title="Contacted Clients"
                        value={stats["contacted_clients"]}
                        cols="2"
                      />
                      <StatCard
                        title="Followups"
                        value={stats["followups"]}
                        cols="2"
                      />
                      <StatCard
                        title="Call Attempts"
                        value={stats["call_attempts"]}
                        cols="2"
                      />
                      <StatCard
                        title="No Answerings"
                        value={stats["not_answerings"]}
                        cols="2"
                      />
                      <StatCard
                        title="Completed Tasks"
                        value={stats["completed_tasks"]}
                        cols="2"
                      />
                    </div>












                    <div className="row layout-spacing">
              <div className="col-lg-12">
                <div className="statbox widget box box-shadow">
                  <div className="widget-content widget-content-area">
                    <h4>
                      Results
                     
                    </h4>
                    <hr />

                    <table
                      id="style-3"
                      className="table style-3 table-hover table-responsive"
                    >
                      <thead>
                        <tr>
                          <th
                            width="5%"
                            className="checkbox-column text-center"
                          >
                            Sr.
                          </th> 
                          <th width="5%" >    Staff Member</th>
                          <th width="5%">     Meetings</th>
                          <th width="5%" >    Calls</th>
                          <th width="5%"  >   Emails  </th>
                          <th width="5%"  >   Whatsapp  </th>
                          <th width="5%"  >   Sales  </th>
                          <th width="5%"  >   Proposals  </th>
                          <th width="5%"  >   Contacted Clients  </th>
                          <th width="5%"  >   Followups  </th>
                          <th width="5%"  >   Call Attempts  </th>
                          <th width="5%"  >   Not Answerings </th>
                          <th width="5%"  >   Completed Tasks </th>
                        </tr>
                      </thead>
                      <tbody>
                        {results.map((task, index) => {
                         
                          return (
                            <tr key={task.id}>
                              <td className="checkbox-column text-center">
                                {index + 1}
                              </td> 
                              <td>{task.name}</td> 
                              <td>{task.meetings}</td>

                              <td>{task.calls}</td>

                              <td>{task.emails}</td>
                              <td>{task.whatsapp}</td>
                              <td>{task.proposals}</td>
                              <td>{task.sales}</td>
                              <td>{task.contacted_clients}</td>
                              <td>{task.followups}</td>
                              <td>{task.call_attempts}</td>
                              <td>{task.not_answerings}</td>
                              <td>{task.completed_tasks}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>














                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img
          className="d-flex mx-auto"
          style={{ marginTop: "125px" }}
          src={require("../../../src/assets/preloaders/loader2.gif")}
          width="50%"
          height="auto"
        />
      )}
    </>
  );
}

export default Report;
