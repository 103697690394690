import { Link, useNavigate } from "react-router-dom";
import StatCard from "../../components/Card/Stat/StatCard";
import LeadModal from "../../components/Modal/LeadModal";

import React, { useState, useEffect, useRef }  from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";
import TaskModal from "../../components/Modal/TaskModal";
import TasksListModal from "../../components/Modal/TasksListModal";
import moment from "moment";
import "@fortawesome/fontawesome-free/css/all.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import LeadTooltip from "./LeadTooltip";
import LeadTooltip2 from "./LeadTooltip2";

// Import necessary components for the search box and date range picker
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import ReactPaginate from 'react-paginate';

function Lead() {
  // USE USE-STATE HOOKS TO MANAGE PAGE CONTENT
  const [actor, setActor] = useState(" ");
  const [action, setAction] = useState("create");
  const [leadId, setLeadId] = useState(null);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [activeTooltip2, setActiveTooltip2] = useState(null);
  let user = JSON.parse(localStorage.getItem("user"));

  const [preloader, setPreloader] = useState(true);
  const [leads, setLeads] = useState([]);

  const [selectedLeads, setSelectedLeads] = useState([]); // ******* State to hold selected leads
  const [users, setUsers] = useState([]); // ******* State to hold users for assigning leads
  const [selectedUser, setSelectedUser] = useState(''); // ******* State to hold the selected user for assignment



  const [stats, setStats] = useState([]);

  const [projects, setProjects] = useState([]);
  const [staff, setStaff] = useState([]);
  const [statuses, setStatuses] = useState([]);

    // State for search query and date range
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, settotalItems] = useState(".....");

  const location = useLocation();
  const path = location.pathname;
  // console.log(path);

  // useNavigate hook for redirect after login
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const tooltipRef = useRef(null);
  const tooltipRef1 = useRef(null);
  useEffect(() => {
    getLeads();
    getLeadsData();

    const role = localStorage.getItem("role");
    setActor(role);

    const handleClickOutside = (event) => { 


          // Function to check if the event.target is inside an element with the class 'tooltip'
  const isInsideTooltip = (element) => {
    while (element) {
      if (element.classList && element.classList.contains('tooltip')) {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  };

  const insideTooltip = isInsideTooltip(event.target);
  if (insideTooltip) { 
  } else {
    
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setActiveTooltip2(null);
    }
    if (tooltipRef1.current && !tooltipRef1.current.contains(event.target)) {
      setActiveTooltip(null);
    }

  }








    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // get leads data required for new lead form
  async function getLeadsData(e) {
    let url = api_url + "lead/create";

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    // console.log(response);
    if (response && response.status == 200) {
      setPreloader(false);

      let projects = response.data.projects;
      let staff = response.data.staff;
      let statuses = response.data.statuses;

      console.log(response);

      setProjects(projects);
      setStaff(staff);
      setUsers(staff); // ******* Fetch users from API for assignment
      setStatuses(statuses);
    } else {
      console.log("something went wrong");
    }
  }

  // get leads data from backend
  async function    getLeads(page = 1) {
    console.log("Page " + page );
      let url = `${api_url}leads?page=${page}`;
   
    if (searchQuery) {
      url += `&search=${searchQuery}&`;
    }
    if (startDate && endDate) {
       url += `&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format(
        'YYYY-MM-DD'
      )}&`;
    }
    if(  document.getElementById('filter_btn') != null)
      {
          document.getElementById('filter_btn').innerHTML ="Please Wait...";
      }
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      // console.log(response);
      if (response && response.status == 200) {
        setPreloader(false);
 

        //   console.log(data);
        setLeads(response.data.leads);
        setStats(response.data);
        setTotalPages(response.data.total_pages ) ; // Update total pages
        setCurrentPage(page); // Update current page
        settotalItems(response.data.total_items );
        if(  document.getElementById('filter_btn') != null)
          {
   document.getElementById('filter_btn').innerHTML ="Filter Leads";
          }
      } else {
        console.log("something went wrong");
      }
    } catch (error) {
      if (
        (error.response && error.response.status === 401) ||
        error.response.status === 403 ||
        error.response.status === 500
      ) {
        // Handle token expiry or invalid token
        console.log("Error: Unauthorized - Token expired or invalid");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      } else {
        console.log("Error fetching leads:", error.message);
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
      setPreloader(false);
    }
  }

  // Function to update project after new record creation
  function updateLeads() {
    getLeads(currentPage);
  }

  // Function to delete lead
  async function deleteLead(id) {
    let url = api_url + `lead/${id}`;

    setPreloader(true);

    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      if (response && response.status === 200) {
        console.log("Lead deleted successfully");

        // Update statuses after deletion
        getLeads(currentPage);
      } else {
        console.log("Error deleting status");
      }

      setPreloader(false);
    } catch (error) {
      console.log("Error deleting status:", error);
    }
  }

  // Function to handle opening of modal
  function openModal(lead) {
    if (lead) {
      setLeadId(lead);
      setAction("edit");
    } else {
      setLeadId(null);
      setAction("create");
    }
    window.$("#leadModal").modal("show");
  }

  function openTaskModal(lead) {
    setLeadId(lead);
    setAction("create");
  }

  function handleTooltipClick(id) {
    if (activeTooltip === id) {
      setActiveTooltip(null);
    } else {
      setActiveTooltip(id);
    }
  }

  function handleTooltipClick2(id) {  
    if (activeTooltip2 === id) {
      setActiveTooltip2(null);
    } else {
      setActiveTooltip2(id);
    }
  }
  // Function to handle search and filter
  // const filteredLeads = leads.filter(lead => {
  //   const leadDate = moment(lead.created_at);
  //   const isDateInRange = (!startDate || leadDate.isSameOrAfter(startDate)) && (!endDate || leadDate.isSameOrBefore(endDate));
  //   const isSearchMatch = lead.lead_lid.toLowerCase().includes(searchQuery.toLowerCase());
  //   return isDateInRange && isSearchMatch;
  // });

    // Function to handle search and filter
    
   // Function to handle page change
   function handlePageClick(event) {
    const selectedPage = event.selected + 1;
    getLeads(selectedPage);
  }


  
  // Handle individual lead selection
  const handleLeadSelect = (id) => {
    setSelectedLeads(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(leadId => leadId !== id);
      } else {
        return [...prevState, id];
      }
    });
 
    // Create an array to store the values of the selected checkboxes
    var selectedValues = [];
    
    // Iterate through the NodeList of checkboxes and push their values to the array
    document.querySelectorAll('input.selected-leads:checked').forEach(checkbox => {
        selectedValues.push(checkbox.value);
    });
     
    if(selectedValues.length > 0)
    {
      document.getElementById("bulkassign").style.display ="block";
    }
    else{
      document.getElementById("bulkassign").style.display ="none";
    }

    console.log(selectedValues);
  };

  const handleSelection = (action)=> {
    if(action)
    {
      document.querySelectorAll('.selected-leads').forEach(checkbox => checkbox.checked = true);
    }
    else{
      document.querySelectorAll('.selected-leads').forEach(checkbox => checkbox.checked = false);
    }
    // console.log(action);

  };


  async function assignLeads_bulk()
  {
    setPreloader(true);
    let url = api_url + "lead/bulk/assign";
 

    const response = await axios.post(url, {
      leadIds: selectedLeads,
      userId: selectedUser,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
    
     

    setPreloader(false);
    // console.log(response);
    if (response && response.status == 200) {  
      alert('Leads assigned successfully');
      getLeadsData();
      getLeads(currentPage);
      setSelectedLeads([]); // Clear selected leads
      setSelectedUser(''); // Clear selected user
      
    } else {
      alert('Something went wrong Please Contact Support');
    }
   


  }

  // ******* Handle bulk lead assignment
  const handleBulkAssign = () => {
    if (selectedLeads.length === 0 || !selectedUser) {
      alert('Please select at least one lead and a user to assign.');
      return;
    }
 
    assignLeads_bulk();
 
  };



  return (
    <>
      {!preloader ? (
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="page-header">
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/leads">Leads</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    List
                  </li>
                </ol>
              </nav>
            </div>

            {/* <div className="row layout-spacing layout-top-spacing">
              <StatCard
                title="Total Leads"
                value={stats.leads ? stats.leads.length : 0}
              />
              <StatCard title="Active Leads" value={stats.active} />
              <StatCard title="Inactive Leads" value={stats.inactive} />
            </div> */}

            <div className="row layout-spacing mt-2">
              <div className="col-lg-12">
                <div className="statbox widget box box-shadow">
                  <div className="widget-content widget-content-area">
                    <h4>
                      ALL LEADS ({totalItems})
                      <button
                        type="button"
                        class="btn btn-primary mb-2 ml-2 float-right"
                        data-toggle="modal"
                        data-target="#leadModal"
                        onClick={() => openModal()}
                      >
                        Add Lead
                      </button>
                    </h4>
                    <hr />

                    {/* Search and Date Range Picker */}
            <div className="row bg-light-dark pt-3 mx-0 mb-3">
              <div className="col-md-3">
              <div class="form-group"><label for="Filter">Filter Lead</label> 
              <input
                  type="text"
                  className="form-control"
                  placeholder="Search here ..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              
              </div>

                
              </div>
              <div className="col-md-9">

              <div class="form-group"><label for="Range">Select Date Range:</label> 
         
<br />
<div class="d-flex">
                <DateRangePicker
                  startDate={startDate}
                  startDateId="start_date_id"
                  endDate={endDate}
                  endDateId="end_date_id"
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                  isOutsideRange={() => false}
                  displayFormat="DD/MM/YYYY"
                  showClearDates={true}
                />
                <button
                  type="button"
                  className="btn btn-primary float-right mt-1 ml-5"
                  onClick={getLeads} >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 22 20" id="filter"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="white" stroke-width="2" transform="translate(-1614 -1629)"><g transform="translate(1615 1630)"><path d="M20 0H0l8 9.46V16l4 2V9.46z"></path></g></g></g></svg>
                  &nbsp; <span id="filter_btn">Filter Leads</span> 
                </button>
                </div>
                </div>
              </div>
              
            </div>
            {/* End of Search and Date Range Picker */}





                       {/* ******* Dropdown to select user for assignment */}
                       
                       <div style={{ display: "none" }} id="bulkassign" className="mt-3 ">
 
 <div className="row layout-spacing mt-2 ">
              <div className="col-lg-12  ">
                <div className="statbox   box box-shadow    pt-3  pl-3  p-3    bg-light-dark  ">
                  <div className=" bg-light-dark   ">


  <div className="form-group   ">
    <label htmlFor="userSelect">Assign to user: </label>
    <select
      id="userSelect"
      value={selectedUser}
      onChange={(e) => setSelectedUser(e.target.value)}
      className="form-control"
    >
      <option value="">Select a user</option>
      {users.map((user) => (
        <option key={user.id} value={user.id}>
          {user.name}
        </option>
      ))}
    </select>
  </div>
  <button onClick={handleBulkAssign} className="btn btn-primary mt-2">
    Assign Selected Leads
  </button>
</div>
</div></div></div></div>
     



                    <table
                      id="style-3"
                      className="table style-3 table-hover table-responsive"
                    >
                      <thead>
                        <tr>
                        <th>
                        <div>
                        <b style={{ cursor: "pointer" }}  onClick={() => handleSelection(true)}>Select</b> |  <b style={{ cursor: "pointer" }}  onClick={() => handleSelection(false)}>Deselect</b>
      </div>
                        </th> {/* ******* New column for bulk select */}
                          
                          <th width="15%">Staff Info</th>
                          <th width="15%">Client Info</th> 
                          <th width="8%">ID</th>
                          <th width="15%">Source Info</th>
                          <th width="10%">Date</th>
                          <th width="5%" className="text-center">
                            Status
                          </th>
                          <th width="5%" className="text-center dt-no-sorting">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {leads.map((lead, index) => {
                        
                          return (
                            <tr key={lead.id}>
                            <td>
                              <input class="selected-leads" style={{ cursor: "pointer" }}
                                value={lead.id}
                                type="checkbox"
                                checked={selectedLeads.includes(lead.id)} // ******* Checkbox to select lead
                                onChange={() => handleLeadSelect(lead.id)}
                              /> &nbsp;
                               {lead.id}
                            </td>
                              
                              <td> 
                                <LeadTooltip
                                  lead={lead}
                                  showTooltip={activeTooltip === lead.id} 
                                  onClick={() =>
                                    handleTooltipClick(lead.id)
                                  }
                                  ref={tooltipRef1}
                                />
                              </td> 
                              <td>
                                  <LeadTooltip2
                                    lead={lead}
                                    showTooltip={activeTooltip2 === lead.id}
                                    onClick={() =>
                                      handleTooltipClick2(lead.id)
                                    }
                                    ref={tooltipRef}
                                  />
                                </td>
                                
                              <td>
                                {lead
                                  ? lead.lead_lid
                                  : "Not Specified"}{" "}
                                <br />
                                <span className="text-muted">
                                  <small>
                                    {moment(lead.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </small>
                                </span>
                              </td>
                              <td>
                                <p className="mb-0">{lead.source}</p>
                                <p className="mb-0">
                                  {lead.source_description}
                                </p>
                              </td>
                              <td>
                                {moment(lead.created_at).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )}
                              </td>
                              <td className="text-center">
                                <span className="shadow-none badge badge-info">
                                  {lead.status
                                    ? lead.status.title
                                    : "Not Specified"}
                                </span>
                              </td>
                              <td className="text-center">
                                <ul className="table-controls">
                                  <li className="px-2">
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-placement="top"
                                      title
                                      data-original-title="Add"
                                      data-toggle="modal"
                                      data-target=".tasksList"
                                      onClick={() => openTaskModal(lead)}
                                    >
                                      <svg
                                        clip-rule="evenodd"
                                        fill-rule="evenodd"
                                        stroke-linejoin="round"
                                        stroke-miterlimit="2"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-16.5.5h15v15h-15zm13.5 10.75c0-.414-.336-.75-.75-.75h-4.5c-.414 0-.75.336-.75.75s.336.75.75.75h4.5c.414 0 .75-.336.75-.75zm-11.772-.537 1.25 1.114c.13.116.293.173.455.173.185 0 .37-.075.504-.222l2.116-2.313c.12-.131.179-.296.179-.459 0-.375-.303-.682-.684-.682-.185 0-.368.074-.504.221l-1.66 1.815-.746-.665c-.131-.116-.293-.173-.455-.173-.379 0-.683.307-.683.682 0 .188.077.374.228.509zm11.772-2.711c0-.414-.336-.75-.75-.75h-4.5c-.414 0-.75.336-.75.75s.336.75.75.75h4.5c.414 0 .75-.336.75-.75zm-11.772-1.613 1.25 1.114c.13.116.293.173.455.173.185 0 .37-.074.504-.221l2.116-2.313c.12-.131.179-.296.179-.46 0-.374-.303-.682-.684-.682-.185 0-.368.074-.504.221l-1.66 1.815-.746-.664c-.131-.116-.293-.173-.455-.173-.379 0-.683.306-.683.682 0 .187.077.374.228.509zm11.772-1.639c0-.414-.336-.75-.75-.75h-4.5c-.414 0-.75.336-.75.75s.336.75.75.75h4.5c.414 0 .75-.336.75-.75z" />
                                      </svg>
                                    </a>
                                  </li>

                                  <li className="px-2">
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-placement="top"
                                      title
                                      data-original-title="Add"
                                      data-toggle="modal"
                                      data-target=".addTask"
                                      onClick={() => openTaskModal(lead)}
                                    >
                                      <svg
                                        clip-rule="evenodd"
                                        fill-rule="evenodd"
                                        stroke-linejoin="round"
                                        stroke-miterlimit="2"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm0 1.5c-4.69 0-8.497 3.808-8.497 8.498s3.807 8.497 8.497 8.497 8.498-3.807 8.498-8.497-3.808-8.498-8.498-8.498zm-.747 7.75h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"
                                          fill-rule="nonzero"
                                        />
                                      </svg>
                                    </a>
                                  </li>

                                  <li className="px-2">
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit Lead"
                                      data-original-title="Edit"
                                      onClick={() => openModal(lead)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-edit-2 p-1 br-6 mb-1"
                                      >
                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                      </svg>
                                    </a>
                                  </li>

                                  {actor === "admin" && (
                                    <li className="px-2">
                                      <a
                                        href="javascript:void(0);"
                                        className="bs-tooltip"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete Lead"
                                        data-original-title="Delete"
                                        onClick={() => deleteLead(lead.id)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="feather feather-trash p-1 br-6 mb-1"
                                        >
                                          <polyline points="3 6 5 6 21 6" />
                                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                        </svg>
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>


                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={totalPages - 1}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img
          className="d-flex mx-auto"
          style={{ marginTop: "125px" }}
          src={require("../../../src/assets/preloaders/loader2.gif")}
          width="50%"
          height="auto"
        />
      )}

      <LeadModal
        action={action}
        updateLeads={updateLeads}
        leadsData={[projects, staff, statuses]}
        leadId={leadId}
      /> 
      <TaskModal
        action="create"
        tasksData={[projects, statuses]}
        leadId={leadId}
      />
      <TasksListModal leadId={leadId} />
    </>
  );
}

export default Lead;
