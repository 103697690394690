import { Link, useNavigate } from "react-router-dom";
import StatCard from "../../components/Card/Stat/StatCard";
import LeadModal from "../../components/Modal/LeadModal";

import React, { useState, useEffect , useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";
import moment from "moment";

import "@fortawesome/fontawesome-free/css/all.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import LeadTooltip from "../Lead/LeadTooltip";
import LeadTooltip2 from "../Lead/LeadTooltip2";

function Dashboard() {
  // USE USE-STATE HOOKS TO MANAGE PAGE CONTENT
  const [actor, setActor] = useState(" ");
  let user = JSON.parse(localStorage.getItem("user"));

  const [preloader, setPreloader] = useState(true);
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(" ");
  const [totalStaff, setTotalStaff] = useState(" ");
  const [totalProjects, setTotalProjects] = useState(" ");

  const [activeTooltip, setActiveTooltip] = useState(null);
  const [activeTooltip2, setActiveTooltip2] = useState(null);

  const navigate = useNavigate();

  const location = useLocation();
  const path = location.pathname;
  // console.log(path);

  const token = localStorage.getItem("token");
  const tooltipRef = useRef(null);
  const tooltipRef2 = useRef(null);
  useEffect(() => {
    getStaff();

    const role = localStorage.getItem("role");
    setActor(role);
    const handleClickOutside = (event) => { 


      

          // Function to check if the event.target is inside an element with the class 'tooltip'
  const isInsideTooltip = (element) => {
    while (element) {
      if (element.classList && element.classList.contains('tooltip')) {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  };

  const insideTooltip = isInsideTooltip(event.target);
  if (insideTooltip) { 
  } else {
    
   
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setActiveTooltip(null); 
    }
    if (tooltipRef2.current && !tooltipRef2.current.contains(event.target)) {
   setActiveTooltip2(null);
    }

  }










    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // get student services
  async function getStaff(e) {
    let url = api_url + "dashboard";

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      // console.log(response.code);

      // console.log(response);
      if (response && response.status == 200) {
        setPreloader(false);

        let leads = response.data.recent_leads;
        let data = response.data;
 
        setLeads(leads);
        setTotalLeads(data.leads);
        setTotalStaff(data.staff);
        setTotalProjects(data.projects);
      } else {
        console.log("something went wrong");
      }
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403 || error.response.status === 500) {
        // Handle token expiry or invalid token
        console.log("Error: Unauthorized - Token expired or invalid");
        localStorage.clear();
        navigate("/login");
        window.location.reload();

      } else {
        console.log("Error fetching leads:", error.message);
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
      setPreloader(false);
    }
  }

  function handleTooltipClick(id) {
    if (activeTooltip === id) {
      setActiveTooltip(null);
    } else {
      setActiveTooltip(id);
    }
  }

  function handleTooltipClick2(id) {
    if (activeTooltip2 === id) {
      setActiveTooltip2(null);
    } else {
      setActiveTooltip2(id);
    }
  }

  return (
    <>
      {!preloader ? (
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="page-header">
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row layout-spacing layout-top-spacing">
              <StatCard
                title="Total Staff"
                value={totalStaff}
              />
              <StatCard title="Total Projects" value={totalProjects} />
              <StatCard title="Total Leads" value={totalLeads} />
            </div>

            <div className="row layout-spacing">
              <div className="col-lg-12">
                <div className="statbox widget box box-shadow">
                  <div className="widget-content widget-content-area">
                    <h4>RECENT LEADS</h4>
                    <hr />

                    <table
                      id="style-3"
                      className="table style-3 table-hover table-responsive"
                    >
                      <thead>
                        <tr>
                          <th
                            width="5%"
                            className="checkbox-column text-center"
                          >
                            Sr.
                          </th>
                          <th width="15%">Staff Info</th>
                          <th width="15%">Client Name</th>
                          <th width="15%">Source Info</th>
                          <th width="10%">Date</th>
                          <th width="5%" className="text-center">
                            Status
                          </th>
                          {/* <th width="5%" className="text-center dt-no-sorting">
                            Action
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {leads.map((lead, index) => {
                          return (
                            <tr key={lead.id}>
                              <td className="checkbox-column text-center">
                                {index + 1}
                              </td>
                              <td>
                                <LeadTooltip
                                  lead={lead}
                                  showTooltip={activeTooltip === lead.id}
                                  onClick={() => handleTooltipClick(lead.id)}
                                  ref={tooltipRef}
                                />
                              </td>
                              <td>
                                <LeadTooltip2
                                  lead={lead}
                                  showTooltip={activeTooltip2 === lead.id}
                                  onClick={() => handleTooltipClick2(lead.id)}
                                  ref={tooltipRef2}
                                />
                              </td>
                              <td>
                                <p className="mb-0">{lead.source}</p>
                                <p className="mb-0">
                                  {lead.source_description}
                                </p>
                              </td>
                              <td>
                                {moment(lead.created_at).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )}
                              </td>
                              <td className="text-center">
                                <span className="shadow-none badge badge-info">
                                  {lead.status
                                    ? lead.status.title
                                    : "Not Specified"}
                                </span>
                              </td>
                              {/* <td className="text-center">
                                <ul className="table-controls">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title
                                      data-original-title="Edit"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-edit-2 p-1 br-6 mb-1"
                                      >
                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title
                                      data-original-title="Delete"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-trash p-1 br-6 mb-1"
                                      >
                                        <polyline points="3 6 5 6 21 6" />
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                      </svg>
                                    </a>
                                  </li>
                                </ul>
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img
          className="d-flex mx-auto"
          style={{ marginTop: "125px" }}
          src={require("../../../src/assets/preloaders/loader2.gif")}
          width="50%"
          height="auto"
        />
      )}
    </>
  );
}

export default Dashboard;
