import { Link, useNavigate } from "react-router-dom";
import StatusModal from "../../components/Modal/StatusModal";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";

function Status() {
  // USE USE-STATE HOOKS TO MANAGE PAGE CONTENT
  const [actor, setActor] = useState(" ");
  const [action, setAction] = useState("create");
  const [statusId, setStatusId] = useState(null);
  let user = JSON.parse(localStorage.getItem("user"));

  const [preloader, setPreloader] = useState(true);
  const [statuses, setStatuses] = useState([]);

  const location = useLocation();
  const path = location.pathname;
  // console.log(path);

  // useNavigate hook for redirect after login
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  useEffect(() => {
    getStatuses();

    const role = localStorage.getItem("role");
    setActor(role);
  }, []);

  /*
|========================================================
| Get listing of all statuses -- API CALLING
|========================================================
*/
  async function getStatuses(e) {
    let url = api_url + "statuses";

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      // console.log(response);
      if (response && response.status == 200) {
        setPreloader(false);

        let statuses = response.data.statuses;
        setStatuses(statuses);
        // console.log(statuses);
      } else {
        console.log("something went wrong");
      }
    } catch (error) {
      if (
        (error.response && error.response.status === 401) ||
        error.response.status === 403 ||
        error.response.status === 500
      ) {
        // Handle token expiry or invalid token
        console.log("Error: Unauthorized - Token expired or invalid");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      } else {
        console.log("Error fetching leads:", error.message);
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
      setPreloader(false);
    }
  }

  // Function to update statuses after new record creation
  function updateStatuses() {
    getStatuses();
  }

  // Function to delete status
  async function deleteStatus(id) {
    let url = api_url + `status/${id}`;

    setPreloader(true);

    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      if (response && response.status === 200) {
        console.log("Status deleted successfully");

        // Update statuses after deletion
        getStatuses();
      } else {
        console.log("Error deleting status");
      }

      setPreloader(false);
    } catch (error) {
      console.log("Error deleting status:", error);
    }
  }

  // Function to handle opening of modal
  function openModal(status) {
    if (status) {
      setStatusId(status);
      setAction("edit");
    } else {
      setStatusId(null);
      setAction("create");
    }
    window.$("#statusModal").modal("show");
  }

  return (
    <>
      {!preloader ? (
        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="page-header">
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/statuses">Statues</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    List
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row layout-spacing pt-3">
              <div className="col-lg-12">
                <div className="statbox widget box box-shadow">
                  <div className="widget-content widget-content-area">
                    <h4>
                      STATUSES ({statuses.length})
                      <button
                        type="button"
                        class="btn btn-primary mb-2 mr-2 float-right"
                        data-toggle="modal"
                        data-target="#statusModal"
                        onClick={() => openModal()}
                      >
                        Add Status
                      </button>
                    </h4>
                    <hr />

                    <table
                      id="style-3"
                      className="table style-3 table-hover table-responsive"
                    >
                      <thead>
                        <tr>
                          <th
                            width="5%"
                            className="checkbox-column text-center"
                          >
                            Sr.
                          </th>
                          <th width="15%">Title</th>
                          <th>Description</th>
                          <th width="10%" className="text-center">
                            Status
                          </th>
                          <th width="10%" className="text-center dt-no-sorting">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {statuses.map((sta, index) => {
                          return (
                            <tr key={index}>
                              <td className="checkbox-column text-center">
                                {" "}
                                {index + 1}{" "}
                              </td>
                              <td>{sta.title}</td>
                              <td>{sta.description}</td>
                              <td className="text-center">
                                <span
                                  className={`shadow-none badge ${
                                    sta.status
                                      ? "badge-success"
                                      : "badge-danger"
                                  }`}
                                >
                                  {sta.status ? "Active" : "Inactive"}
                                </span>
                              </td>
                              <td className="text-center">
                                <ul className="table-controls">
                                  <li className="px-3">
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit Status"
                                      data-original-title="Edit"
                                      onClick={() => openModal(sta)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-edit-2 p-1 br-6 mb-1"
                                      >
                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                      </svg>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="bs-tooltip"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      data-original-title="Delete"
                                      onClick={() => deleteStatus(sta.id)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-trash p-1 br-6 mb-1"
                                      >
                                        <polyline points="3 6 5 6 21 6" />
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                      </svg>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img
          className="d-flex mx-auto"
          style={{ marginTop: "125px" }}
          src={require("../../../src/assets/preloaders/loader2.gif")}
          width="50%"
          height="auto"
        />
      )}

      <StatusModal
        action={action}
        updateStatuses={updateStatuses}
        statusId={statusId}
      />
    </>
  );
}

export default Status;
