import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../config";

function ProjectModal({action, updateProjects, projectId }) {
  // USE-STATE HOOKS FOR MANAGING SERVICE MODEL
  const [serverError, setServerError] = useState(false);
  const [preloader, setPreloader] = useState(false);
  const [validationErrors, setValidationErros] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  // Staff Inputs
  const [title, setTitle] = useState(" ");
  const [description, setDescription] = useState(" ");
  // const [image, setImage] = useState(" ");
  const [detail, setDetail] = useState(" ");
  const [country, setCountry] = useState(" ");
  const [state, setState] = useState(" ");
  const [city, setCity] = useState(" ");
  const [isActive, setIsActive] = useState(false);

  const [staffId, setStaffId] = useState(null);

  useEffect(() => {
    if (projectId) {
      getProject(projectId);
      setMessage("");
      setErrors([]);
    }else{
      setTitle(" ");
      setDescription(" ");
      // setImage(" ");
      setDetail(" ");
      setCountry(" ");
      setState(" ");
      setCity(" ");
      setIsActive(" ");
      setMessage("");
      setErrors([]);
    }
  }, [projectId]);

  /*
|========================================================
| Get Project detail -> Edit Record
|========================================================
*/
  async function getProject(record) {
    try {
      setTitle(record.title);
      setDescription(record.description);
      // setImage(record.image);
      setDetail(record.detail);
      setCountry(record.country);
      setState(record.state);
      setCity(record.city);
      setIsActive(record.status);
    } catch (error) {
      console.log("Error fetching status:", error);
    }
  }

  /*
|========================================================
| Add New Project -- API CALLING
|========================================================
*/
  async function storeProject(e) {
    e.preventDefault();

    setPreloader(true);

    // get token from local storage
    const token = localStorage.getItem("token");
    // console.log(token);

    // MAKE FORMDATA FOR CALLING STORE NEW SERVER API
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    // formData.append("image", image);
    formData.append("detail", detail);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("status", isActive);
    const body = formData;

    let url = "";
    // Create/Update END-POINT
    if (action == "create") {
      url = api_url + "project/store";
    } else {
      url = api_url + "project/" + projectId.id;
    }

    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    // console.log(response);
    if (response && response.status == 200) {
      setPreloader(false);

      console.log(response.data);

      let data = response.data;
      if (data.status == 200) {

        updateProjects(true);
        let message = data.message;
        setMessage(message);
        setSuccessMessage(true);

        window.location.reload();

        // navigate("/student/services");
      } else {
        setErrors(data.errors);
        setValidationErros(true);
      }
    } else {
      console.log("API CALLING FAILED");
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id="projectModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Project Information
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                x
              </button>
            </div>

            <div className="modal-body py-0">
              {/* display alerts */}
              {validationErrors ? (
                <div
                  className="alert alert-danger alert-dismissible m-2"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <ul className="mb-0">
                    {errors.map((error) => {
                      return (
                        <li key={error.id} className="list">
                          <span className="repo-text">{error} </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <></>
              )}
              {message ? (
                <div
                  className="alert alert-success alert-dismissible m-2"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h6 className="mb-0">{message}</h6>
                </div>
              ) : (
                <></>
              )}
            </div>

            <form onSubmit={storeProject}>
              <div className="modal-body p-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="title">
                        Title <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="title"
                        name="title"
                        value={title}
                        placeholder="Title"
                        required
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="description"
                        name="description"
                        value={description}
                        placeholder="Description"
                        required
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="detail">Detail</label>
                      <textarea
                        className="form-control mb-4"
                        id="detail"
                        name="detail"
                        value={detail}
                        placeholder="Detail"
                        required
                        onChange={(e) => setDetail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-4 pr-md-0">
                    <div className="form-group">
                      <label htmlFor="country">Country</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="country"
                        name="country"
                        value={country}
                        placeholder="Country"
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-4 px-1">
                    <div className="form-group">
                      <label htmlFor="description">State</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="state"
                        name="state"
                        value={state}
                        placeholder="State"
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-4 pl-md-0">
                    <div className="form-group">
                      <label htmlFor="description">City</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        id="city"
                        name="city"
                        value={city}
                        placeholder="City"
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="isActive">Status</label>
                      <select
                        className="form-control"
                        id="status"
                        name="isActive"
                        value={isActive}
                        onChange={(e) => setIsActive(e.target.value)}
                      >
                        <option>Account Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                  </div>

                  {/* <div className="col-md-4 pr-0">
                    <div className="form-group">
                      <label htmlFor="country">Country</label>
                      <select className="form-control" id="country">
                        <option>select country</option>
                        <option value="male">Male</option>
                        <option value="female">Femail</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 px-1">
                    <div className="form-group">
                      <label htmlFor="state">State</label>
                      <select className="form-control" id="state">
                        <option>select state</option>
                        <option value="male">Male</option>
                        <option value="female">Femail</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 pl-0">
                    <div className="form-group">
                      <label htmlFor="city">City</label>
                      <select className="form-control" id="city">
                        <option>select city</option>
                        <option value="male">Male</option>
                        <option value="female">Femail</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div> */}
                  <hr />

                  {/* <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="image">Profile image</label>
                      <input
                        type="file"
                        className="form-control mb-4"
                        id="image"
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn" data-dismiss="modal">
                  <i className="flaticon-cancel-12" />
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectModal;
